<template>
  <v-container class="my-6">
    <v-form
      id="payment-form"
      ref="paymentForm"
      v-model="paymentForm"
      lazy-validation
      @submit.prevent="validateCart"
    >

      <v-row  class="justify-center align-center">
        <v-col
          v-if="paymenterror && paymenterror.trim().length > 1"
          cols="12"
        >
          <v-alert
            prominent
            type="error"
          >
            <v-row align="center">
              <v-col class="grow">
                {{ paymenterror }}
              </v-col>
              <v-col class="shrink">
                <v-btn @click="paymenterror = null">
                  {{ $t("Close") }}
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="8"
          xl="8"
        >
          <base-card class="mb-4" >
            <div class="pa-4">
              <v-row>
                <v-col
                  cols="12"
                  order="0"
                >
                  <div class="d-flex">
                    <v-avatar
                      size="30"
                      color="primary"
                      class="me-3"
                    >
                      <p class="white--text mb-0">
                        3
                      </p>
                    </v-avatar>
                    <h3 class="font-weight-light">
                      {{ $t('Paiement') }}
                    </h3>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  order="2"
                  order-md="1"
                  md="6"
                  lg="5"
                >
                  <v-row>
                    <v-col cols="12">
                      <!--                      <stripe-element-payment
                                              ref="paymentRef"
                                              :pk="pk"
                                              :elements-options="elementsOptions"
                                              :confirm-params="confirmParams"
                                            />-->
                      <div id="payment-element">
                        <!-- Elements will create form elements here -->
                      </div>
                    </v-col>
                    <v-col cols="12" v-if="payment.id">
                      <v-btn
                        block
                        type="submit"
                        color="primary"
                        class="text-capitalize font-600 mb-4"
                        :disabled="!paymentForm || !$store.state.login || order.packages.filter((e) => !e.transporter_id).length > 0"
                      >
                        {{ $t("Payer") }}(&euro;{{ payment.amount }})
                      </v-btn>
                    </v-col>

                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  lg="7"
                  order="1"
                  order-md="last"
                >
                  <v-row>
                    <v-col cols="12">
                      <v-alert
                        type="info"
                        text
                        dense
                        class="pl-0 pl-lg-2 pr-lg-2"
                      >
                        {{ $t( "Politique de retour Planete-market.eu: Si vous n’êtes pas satisfait d'un produit que vous avez commandé auprès d'Planete-market.eu ou si celui-ci est défectueux ou endommagé, vous pouvez nous le retourner sous 30 jours suivant la date de livraison, et nous vous rembourserons ou remplacerons l'intégralité de l'article. Pour plus d’informations, veuillez consulter notre page en savoir plus sur les Retours et remboursements. Si un défaut apparaissait sur votre produit… En savoir plus") }}

                        <v-btn color="info">
                          {{ $t("Lire la suite") }}
                        </v-btn>
                      </v-alert>
                    </v-col>
                    <v-col cols="12">
                      <v-alert
                        type="info"
                        text
                        class="pl-0 pl-lg-2 pr-lg-2"
                      >
                        {{ $t("Toutes reclamation doit se faire 72 heures après le dépot de la commande au point relay") }}
                        <v-btn color="info">
                          {{ $t("Lire la suite") }}
                        </v-btn>
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          </base-card>
        </v-col>

      </v-row>
    </v-form>
    <v-dialog
      v-model="loading"
      persistent
      width="70"
      class="overflow-hidden transparent"
    >
      <v-card
        width="70"
        height="70"
        class="overflow-hidden"
      >
        <v-row
          no-gutters
          justify="center"
          align="center"
          class="fill-height align-self-center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <v-progress-circular
              indeterminate
              color="primary"
              class="mx-auto"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
    >
      {{ snackBarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="snackBarColor"
          text
          v-bind="attrs"
          @click="isSnackbarBottomVisible = false"
        >
          {{ $t("Fermer") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
  import { orderBy } from 'lodash/collection'

  import 'vue-form-wizard/dist/vue-form-wizard.min.css'
  import { i18n } from '../../plugins/i18n'
  import { VueMaskDirective } from 'v-mask'
  import AuthenticationForm from '../../components/AuthenticationForm.vue'
  import { StripeElementPayment } from '@vue-stripe/vue-stripe';
  Vue.directive('mask', VueMaskDirective)

  export default {
    components: {
      AuthenticationForm,
      StripeElementPayment,
    },
    data: () => ({
      panel: [],
      step: 0,
      paymentForm: null,
      cardNumber: null,
      cardName: null,
      cardCvx: null,
      cardExpirationDate: null,
      validAddressForm: false,
      valide: false,
      loading: false,
      loading_points: false,
      loading_cities: false,
      create_new: false,
      security_tax: 0,
      commission_minimal: 0,
      donate: 0,
      usercountry: 0,
      association: null,
      system_transporters: [],
      shipment_methods: [],
      payment_methods: [],
      relay_points: [],
      packages: [],
      package: {},
      errors: {
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        country: false,
        address: false,
        zip: false,
        city: false,
      },

      salutationRules: [v => !!v || i18n.t('Requis.')],
      firstNameRules: [v => !!v || i18n.t('Le prénom est requis.')],
      lastNameRules: [v => !!v || i18n.t('Le nom de famille est requis.')],
      telRules: [v => !!v || i18n.t('Le numéro de téléphone est requis.')],
      addressRules: [v => !!v || i18n.t('L\'adresse est requise.')],
      transporterRules: [v => !!v || i18n.t('Un transport est requis')],
      pointRules: [v => !!v || i18n.t('Un Point Relay est requis')],
      cardCvxRules: [v => !!v || i18n.t('Le CVX est requis')],
      cardExpirationDateRules: [v => !!v || i18n.t('La date d\'expiration de la carte est requise')],
      cardNumberRules: [v => !!v || i18n.t('Le numéro de la carte est requis')],
      cardNameRules: [v => !!v || i18n.t('Le nom sur le panier est obligatoire')],
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      radios: null,
      radiosTwo: null,
      cart: {
        user: {
          country_code: null,
        },
        country_code: null,
        city: null,
        post_code: null,
        packages: [],
      },
      order: {
        user: {
          country_code: null,
        },
        country_code: null,
        city: null,
        post_code: null,
        packages: [],
      },
      addresses: [],
      deliveryaddress: [],
      snackBarMessage: '',
      snackBarColor: null,
      isSnackbarBottomVisible: false,
      settings: {},
      payment: {},
      paymenterror: null,

      pk: 'your-publishable-key',
      stripe_pk: 'your-publishable-key',
      elementsOptions: {
        appearance: {}, // appearance options
      },
      confirmParams: {
        return_url: 'http://localhost:8080/payments', // success url
      },
      stripe: null,
      elements: {}
    }),
    computed: {
      ...mapGetters(['getCartProducts', 'unreadNotifications']),
      ...mapState({
        user: 'user',
        salutations: 'salutations',
        countries: 'countries',
        displayNotification: 'display_notification',
      }),

      cartTotal () {
        let total = 0
        this.getCartProducts.forEach(product => {
          total += product.amount * product.qty
        })
        return total
      },
      backButtonLabel () {
        return this.$t('Précédent')
      },
      nextButtonLabel () {
        if (this.step === 2) {
          return this.$t('Procéder au paiement')
        }
        return this.$t('Suivant')
      },
      finishButtonLabel () {
        return this.$t('Payer')
      },
    },
    watch: {

    },
    mounted () {
      this.loadMangopay()
    },
    created () {
      this.cart = this.$store.state.cart
      this.paymenterror = this.$router.currentRoute.query.paymenterror || null
      this.getSettings()
      //if (this.$store.state.login) {
        this.loadPayment()
      //}
    },
    methods: {
      orderBy,
      ...mapActions(['addCart', 'removeCart']),
      ...mapMutations([
        'pushItem', // map `this.increment()` to `this.$store.commit('increment')`
        'removeItem', // map `this.increment()` to `this.$store.commit('increment')`
        // `mapMutations` also supports payloads:
        'setValue', // map `this.incrementBy(amount)` to `this.$store.commit('incrementBy', amount)`
        'updateChat',
      ]),

      openChat(p) {
        this.updateChat({type: 'user', typeId: p.seller_id, title: p.seller.pseudo, image: p.seller.avatar})

      },
      connected () {

        this.loadPayment()
      },
      loadMangopay () {
        const mangoScript = document.createElement('script')
        mangoScript.setAttribute('src', '/managopay-kit.min.js')
        document.head.appendChild(mangoScript)
      },
      getSettings () {
        // eslint-disable-next-line no-undef
        axios.get('/checkout_settings').then((response) => {
          if (response.data) {
            this.settings = response.data
            if (response.data.system_transporters) {
              this.system_transporters = response.data.system_transporters
            }
            if (response.data.association) {
              this.association = response.data.association
            }
            if (response.data.security_tax) {
              this.security_tax = response.data.security_tax
            }
            if (response.data.commission_minimal) {
              this.commission_minimal = response.data.commission_minimal
            }
            if (response.data.donate) {
              this.donate = response.data.donate
            }
          //  this.stripe_pk = this.settings.stripe_pk


            // Set your publishable key: remember to change this to your live publishable key in production
            // See your keys here: https://dashboard.stripe.com/apikeys
           // this.stripe = Stripe(this.stripe_pk);

          }
        })
      },
      loadPayment () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios(
          {
            method: 'post',
            url: `/payments/${this.$router.currentRoute.params.id}?form=1`,

            validateStatus: status => true,
          })
          .then((response) => {
            this.loading = false
            if (response.data && response.data.error) {
              this.toast(this.$t(response.data.message), 'red')
              if (response.data.cart) {
                this.setValue({ type: 'cart', value: { products: [] } })
                localStorage.removeItem('planete@cart')
              }
            } else {
              this.payment = response.data.payment
              this.stripe_pk = response.data.stripe_pk
              this.stripe = Stripe(this.stripe_pk);

              const options = {
                clientSecret: this.payment.preregistration_data,
                // Fully customizable with appearance API.
                appearance: {
                  theme: 'stripe',
                  variables: {
                    colorPrimary: '#0472b7',
                    colorBackground: '#ffffff',
                    colorText: '#30313d',
                    colorDanger: '#FF5353',
                    fontFamily: 'Ideal Sans, system-ui, sans-serif',
                    spacingUnit: '2px',
                    borderRadius: '4px',
                    // See all possible variables below
                  }
                  },
              };

              // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
              this.elements = this.stripe.elements(options);

              // Create and mount the Payment Element
              const paymentElementOptions = {layout: 'accordion'};
              const paymentElement = this.elements.create('payment', paymentElementOptions);
              paymentElement.mount('#payment-element');

              this.getOrder()



            }
          }).then(() => {
          this.loading = false
        }).catch((error) => {
          this.loadiing = false;
        })
      },
      getOrder () {
        this.loading = true
        axios.get('/orders/' + this.payment.payable_id).then((response) => {
          this.order = response.data
          this.card = response.data
        }).then(() => {
          this.loading = false
        })
      },
      scrollToTop () {
        // This prevents the page from scrolling down to where it was previously.
        if ('scrollRestoration' in history) {
          history.scrollRestoration = 'manual'
        }
        // This is needed if the user scrolls down during page load and you want to make sure the page is scrolled to the top once it's fully loaded. This has Cross-browser support.
        window.scrollTo(0, 0)
      },
      async validateCart () {
        const isFormValid = this.$refs.paymentForm.validate()
        if (!isFormValid) {
          console.log("invidate")
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        }

        var elements = this.elements
        const {error} = await this.stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: this.payment.redirect_url,
          },
        });

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          const messageContainer = document.querySelector('#error-message');
          messageContainer.textContent = error.message;
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      },
      async validateAddress () {
        const isFormValid = this.$refs.addressForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        } else {
          this.loading = true
          // eslint-disable-next-line no-undef
          var response = await axios.post('/order/address', {
            id: this.order.id,
            user: this.order.user,
            packages: this.order.packages
              .map((e) => { return { transporter_id: e.transporter_id, point_id: e.point_id, id: e.id } }),
          })
          this.loading = false
          if (response.data && response.data.error) {
            this.toast(this.$t(response.data.message), 'red')
          } else {
            this.order = response.data
            this.order.packages = this.order.packages.map((el) => {
              return {
                ...el,
                points: el.transporter ? el.transporter.points || [] : [],
              }
            })
            this.step = this.order.step
            this.scrollToTop()
            return true
          }
        }
      },
      async validPaymentForm() {


        const isFormValid = this.$refs.paymentForm.validate()
        if (!isFormValid) {
          this.toast(this.$t('Veuillez remplir tous les champs obligatoires.'), 'red')
          return false
        } else {
          this.loading = true
          // get manago payment token
          // eslint-disable-next-line no-undef
          /* var response = await axios.post(this.payment.card_registration_url, {
            accessKeyRef: this.payment.access_key,
            data: this.payment.preregistration_data,
            cardNumber: this.cardNumber,
            cardExpirationDate: this.cardExpirationDate,
            cardCvx: this.cardCvx,
          }) */
          const options = {
            clientSecret: this.elementsOptions.clientSecret,
            // Fully customizable with appearance API.
            appearance: {/*...*/},
          };

          // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
          const elements = this.stripe.elements(options);

          // Create and mount the Payment Element
          const paymentElementOptions = {layout: 'accordion'};
          const paymentElement = elements.create('payment', paymentElementOptions);
          paymentElement.mount('#payment-element');
          //this.confirmParams.return_url = this.payment.redirect_url
          const {error} = await this.stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
              return_url: this.payment.redirect_url,
            },
          });

          if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            const messageContainer = document.querySelector('#error-message');
            messageContainer.textContent = error.message;
          } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
          }
          //this.$refs.paymentRef.submit();
          return;
          var form = document.createElement('form')
          form.setAttribute('action', this.payment.card_registration_url)
          form.setAttribute('method', 'post')
          form.setAttribute('style', 'display: none')
          document.getElementsByTagName('body')[0].appendChild(form)

          // Add card registration data to the form
          form.appendChild(this.getInputElement('data', this.payment.preregistration_data))
          form.appendChild(this.getInputElement('accessKeyRef', this.payment.access_key))
          form.appendChild(this.getInputElement('cardNumber', this.cardNumber.replace(/ /g, '')))
          form.appendChild(this.getInputElement('cardExpirationDate', this.cardExpirationDate.replace('/', '')))
          form.appendChild(this.getInputElement('cardCvx', this.cardCvx))
          form.appendChild(this.getInputElement('returnURL', this.payment.redirect_url))

          // Submit the form
          form.submit()
          // this.loading = false
          /* if (response.data && response.data.error) {
            this.toast(this.$t(response.data.message), 'red')
          } else {
            this.payment.registration_data = response.data

            // go to payment page
            // return true
          } */
        }
      },

      getInputElement (name, value) {
        var input = document.createElement('input')
        input.setAttribute('type', 'hidden')
        input.setAttribute('name', name)
        input.setAttribute('value', value)
        return input
      },
      toast (message, color) {
        this.isSnackbarBottomVisible = true
        this.snackBarColor = color
        this.snackBarMessage = message
      },
      removeCartx (item) {
        this.removeCart(item)
      },
      addCartx (item) {
        this.addCart(item)
      },

      addressChanged (event) {
        var item = this.getItem(this.addresses, 'id', event.target.value)

        this.order.address = item
      },
      countryChanged (event) {
        // console.log(event.target.value);
        var code = this.order.user.country_code
        // this.order.user.country_code = this.order.country_code
        this.resetTransporter(event)
        this.getCities(code)
        // this.relaypoints(0);
      },
      getCities (code) {
        var country = this.getItem(this.countries, 'code', code)
        var _this = this
        // this.order.address.city_id = null;

        if (!country.cities || country.cities.length === 0) {
          this.loading_cities = true
          axios.get('/countries/' + code).then((response) => {
            _this.loading_cities = false
            country.cities = response.data
            Vue.set(_this.countries, _this.getIndex(_this.countries, 'code', code), country)
          })
        }
        ;
        return country.cities || []
      },
      cityChanged (event) {
        // this.relaypoints(0);

      },
      zipChanged (event) {
        // this.relaypoints(0);
        this.order.packages.map((e) => { return { ...e, transporter_id: null } })
      },
      resetTransporter (event) {
        this.order.packages.map((e) => { return { ...e, transporter_id: null } })
      },
      transporterChanged (event, pkg) {
        this.getTransporter(pkg)
        this.getRelayPoints(pkg)
      },
      relaypoints (local = 0) {
        var transporter_id = this.order.transporter_id
        var country_id = this.order.user.country_code
        var city_id = this.order.user.city

        if (!country_id) {
          return []
        }
        if (!transporter_id) {
          return []
        }
        if (!this.order.user.zip || this.order.user.zip.length < 3) {
          return []
        }
        this.relay_points = []
        var that = this
        that.loading_points = true
        axios.get('/relay_points/' + transporter_id + '?&local=' + local,
                  { params: { country_id: country_id, city_id: city_id, zip: this.order.user.zip } })
          .then((response) => {
            this.relay_points = response.data
            that.loading_points = false
          }).catch(error => {
            that.loading_points = false
            alert(this.$t('Aucun point relais trouvé ici.'))
          })
      },
      getRelayPoints (pkg) {
        // pkg.points = this.system_transporters.find((e) => e.id === pkg.transporter_id).points || []

        var transporter_id = pkg.transporter_id
        var country_id = this.order.user.country_code
        var city_id = this.order.user.city

        if (!country_id) {
          return []
        }
        if (!transporter_id) {
          return []
        }
        if (!this.order.user.zip || this.order.user.zip.length < 3) {
          return []
        }
        this.relay_points = []
        var that = this
        pkg.loading_points = true
        axios.get('/transporters/relaypoints/' + transporter_id + '?&local=0',
                  { params: { country_id: country_id, city_id: city_id, zip: this.order.user.zip } })
          .then((response) => {
            pkg.points = response.data
            pkg.loading_points = false
          }).catch(error => {
            pkg.loading_points = false
            alert(this.$t('Aucun point relais trouvé ici.'))
          })
      },
      transporters (pk) {
        console.log('pk', pk.seller)

        var trans = [] // pk.seller.transporters || [];
        if (pk.seller.country_code === 'FR') {
          for (var i = 0; i < this.system_transporters.length; i++) {
            if (this.checkTariff(pk, this.system_transporters[i])) {
              trans.push(this.system_transporters[i])
            }
          }
        }
        /**/
        for (let i = 0; i < pk.seller.transporters.length; i++) {
          if (this.checkTariff(pk, pk.seller.transporters[i])) {
            trans.push(pk.seller.transporters[i])
          }
        }

        return trans
      },
      getTransporter (pk) {
        pk.relay_point = null;
        pk.has_relays = null;
        if (pk.transporter_id) {
          var trans =  this.transporters(pk);
          for (var i = 0; i < trans.length; i++) {

            if (trans[i].id == pk.transporter_id) {
             pk.has_relays = trans[i].has_relays;
            }
          }
        }else{
          pk.relay_point = null;
          pk.has_relays = null;
        }

      },
      isSystemTransporter (transporter_id) {
        for (var i = 0; i < this.system_transporters.length; i++) {
          if (parseInt(transporter_id) === parseInt(this.system_transporters[i].id)) {
            return true
          }
        }
        return false
      },

      close () {
        $('#paymentbox').modal('hide')
        $('#paymentbox').find('.modal-body').html('')
      },
      pay (pkg) {
        /* $('#paymentbox').find('.modal-body').html('')
        $('#paymentbox').modal('show')
        this.loading = true
        axios.post('/card-registration', {
          currency: this.order.currency || 'EUR',
          cost: pkg.total,
          payment_type: '<?php echo e(\App\MangoPayApi::package); ?>',
          cardtype: this.order.card_type || '',
          returnurl: 'packages.pay',
          entity_id: pkg.id,
        }).then((response) => {
          this.loading = false
          $('#paymentbox').find('.modal-body').html(response.data)
        }).catch((error) => {
          this.loading = false
        }) */
      },
      cardRegistration () {
        this.loading = true
        // eslint-disable-next-line no-undef
        axios.post('/card-registration', {
          currency: this.order.currency || 'EUR',
          amount: this.order.total,
          card_type: this.order.card_type || '',
          payment_type: this.settings.payment_type,
          returnurl: this.settings.payment_url/* 'cart.pay' */,
          order_id: this.order.id,
        }).then((response) => {
          this.loading = false
          this.payment = response.data
          this.validPaymentForm()
        }).catch((error) => {
          console.log(error)
          this.loading = false
          this.cardError = error
        })
      },
      getTax () {
        // if (this.order.donate) {
        //     t += this.donate;
        // }
        var security_fee = parseFloat(((this.security_tax / 100) * this.subTotal()).toFixed(2))
        if (security_fee < this.commission_minimal) { return this.commission_minimal }
        return security_fee// .toFixed(2);
      },
      getTotal () {
        var t = parseFloat(this.getTax()) + parseFloat(this.subTotal())

        // if (this.order.donate) {
        //     t += this.donate;
        // }

        return t ? t.toFixed(2) : t
      },
      subTotal () {
        var t = 0
        for (var i = 0; i < this.order.packages.length; i++) {
          t += parseFloat(this.getPackageTotal(this.order.packages[i]))
        }
        return t // t.toFixed(2);
      },
      checkTariff (pk, t) {
        var tas = t.tariffs || []

        for (var i = 0; i < tas.length; i++) {
          if ((tas[i].min_weight < pk.total_weight + 1) && (tas[i].max_weight > pk.total_weight - 1) && (tas[i].country_code === this.order.user.country_code)) {
            // return tas[i];
            return true
          }
        }
        // not found so we take the first price where min weight is less than the total weight
        /* for(var i=0;i<tas.length;i++){
            if(tas[i].min_weight < pk.total_weight+1  && tas[i].country_code == this.order.user.country_code){
                return tas[i];
            }
        } */
        return false
      },
      getTariffValue (pk) {
        var tariff = this.getTariff(pk)
        if (this.isSystemTransporter(pk.transporter_id)) {
          return parseFloat(tariff.weight_price_ttc)
        }
        if (parseInt(pk.exportation) === 0) {
          if (tariff.weight_price_ttc) { return parseFloat(tariff.weight_price_ttc) }

          return parseFloat(tariff.weight_price)
        }

        return parseFloat(tariff.weight_price)
      },
      getTariff (pk) {
        if (!pk.transporter_id) {
          return { weight_price: 0, not_exist: true }
        }

        // using system transporters instead of seller transporter.
        var tas = this.getItem(pk.seller.transporters || [], 'id', pk.transporter_id).tariffs || []
        var tasys = this.getItem(this.system_transporters || [], 'id', pk.transporter_id).tariffs || []

        tasys.forEach((item) => {
          tas.push(item)
        })

        for (var i = 0; i < tas.length; i++) {
          // pk.total_weight = 2

          // console.log("country code",this.order.user.country_code);

          if ((tas[i].min_weight < pk.total_weight) && (tas[i].max_weight >= pk.total_weight) && (tas[i].country_code === this.order.user.country_code)) {
            return tas[i]
          }
        }

        // not found so we take the first price where min weight is less than the total weight
        // for (var i = 0; i < tas.length; i++) {
        //     if ((tas[i].min_weight < pk.total_weight + 1) && (tas[i].country_code === this.order.user.country_code)) {
        //         return tas[i];
        //     }
        // }

        return { weight_price: 0, weight_price_ttc: 0, not_exist: true }
      },
      getTva (pk) {
        return 19.0
      },
      getPackageTotal (pk) {
        var to = 0
        for (var i = 0; i < pk.items.length; i++) {
          to += parseFloat(pk.items[i].qty * pk.items[i].price)
        }

        var tariffvalue = this.getTariffValue(pk)

        // if(parseInt(pk.exportation) === 0){
        //     if(tariff.weight_price_ttc)
        //         return (to + parseFloat(tariff.weight_price_ttc )).toFixed(2);
        //
        //     return (to + parseFloat(tariff.weight_price )).toFixed(2);
        // }
        //
        // return (to + parseFloat(tariff.weight_price)).toFixed(2);
        return (to + tariffvalue).toFixed(2)
        /* if (!pk.country_id || !pk.transporter_id) {
             return {weight_price: 0, not_exist: true};
         } */
        // return this.getItem(this.getItem(this.transporters(pk), 'id', pk.transporter_id).tariffs || [], "country_id", pk.country_id)
      },
      getItem (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return items[i]
          }
        }
        return false
      },

      getIndex (items, field, value) {
        for (var i = 0; i < items.length; i++) {
          if (items[i][field] == value) {
            return i
          }
        }
        return -1
      },

      removePackage (pk, index) {
        this.loading = true
        var _this = this
        axios.post('/orders/remove/package', { order_id: this.order.id, package_id: pk.id })
          .then((response) => {
            _this.loadiing = false
            _this.order.sub_total -= pk.total
            _this.order.packages.splice(index, 1)
            if (_this.order.packages.length == 0) {
              document.location.reload()
            }
          })
      },
      getTransporters (pkg) {
        // pkg.seller.transporters
        return this.system_transporters
        var _this = this
        if (this.order.country_id) {
          _this.transporters = []
          axios.get('/countries/transporters/' + this.order.country_id)
            .then(response => {
              _this.transporters = response.data
              _this.getRelayPoints()
            })
        }
      },

    },
  }
</script>
